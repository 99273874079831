

















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DynamicForm from "@/components/DynamicForm.vue";
import FormContainer from "@/components/FormContainer.vue";
import EntitySelect from "@/components/EntitySelect.vue";

@Component({
  components: {
    DynamicForm,
    FormContainer,
    EntitySelect
  }
})
export default class CaseManagement extends Vue {
  @Prop()
  public id!: any;

  private crud!: any;
  private householdMember: any = null;
  private prescreening: any = null;

  private actions: any[] = [
    {
      id: "intake",
      title: "intake"
    },
    {
      id: "pending",
      title: "pending"
    },
    {
      id: "external referral",
      title: "external referral"
    },
    {
      id: "archived",
      title: "archived"
    }
  ];

  async mounted() {
    this.crud = this.$service.crud.prescreenings;
    this.householdMember = await this.$service.providers.householdMembers.fetchItemAsync(
      this.id
    );
    this.prescreening = await this.$service.providers.prescreenings.fetchItemAsync(
      this.householdMember.prescreeningId
    );
  }

  async submit() {
    try {
      await this.crud.saveAsync(this.prescreening);
      this.$q.notify({
        message: "Item saved successfully!",
        color: "green-4",
        timeout: 2000
      });
    } catch (error) {
      console.log(error);
      this.$q.notify({ message: error, color: "red-4" });
    }
  }

  bindPrescreeningActionTaken(e: any) {
    this.prescreening.actionTaken = e.id;
    if (
      this.prescreening.actionTaken &&
      this.prescreening.actionTaken !== "external referral"
    ) {
      this.prescreening.externalReferral = "";
    }
  }
}
